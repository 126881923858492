<template lang="pug">
    .settings
        perfect-scrollbar._navigation
            button._navigation-item(@click="currentTab = 'information'" :class="{ 'active': currentTab === 'information' }") {{ locale("information") }}
            button._navigation-item(@click="currentTab = 'added-info'" :class="{ 'active': currentTab === 'added-info' }") {{ locale("added-info") }}
            button._navigation-item(@click="currentTab = 'about'" :class="{ 'active': currentTab === 'about' }") {{ locale("about") }}
            button._navigation-item(@click="currentTab = 'career'" :class="{ 'active': currentTab === 'career' }") {{ locale("career") }}
            button._navigation-item(@click="currentTab = 'education'" :class="{ 'active': currentTab === 'education' }") {{ locale("education") }}
            //button._navigation-item(@click="currentTab = 'photo'" :class="{ 'active': currentTab === 'photo' }") {{ locale("photos") }}
            //button._navigation-item(@click="currentTab = 'photo'" :class="{ 'active': currentTab === 'photo' }") {{ photosReady }}
            // button._navigation-item(@click="currentTab = 'avatar'",:class="{ 'active': currentTab === 'avatar' }") Аватар
        perfect-scrollbar._wrap
            loading(v-if="currentTab === 'photo' && photoLoading")
            change-photos(v-if="currentTab === 'photo'" :hideExpample="photosReady" :show-gift="false" photoType="anfas and profile")
            change-photos(v-if="currentTab === 'avatar'", photoType="avatar")
            about-settings-form(v-if="currentTab === 'about'" :person="personProfile" @update="getPersonProfile")
            info-settings-form(v-if="currentTab === 'added-info'" :person="personProfile" @update="getPersonProfile")
            career-settings-form(v-if="currentTab === 'career'" :person="personProfile" @update="getPersonProfile")
            education-settings-form(v-if="currentTab === 'education'" :person="personProfile" @update="getPersonProfile")
            ._inputs-wrap(v-if="currentTab === 'information'")
                ._item._item-accepted
                    label {{ locale("name") }}
                    input.less-width(
                        v-model="$v.personNameMutable.$model",
                        :disabled="nameError.length ? true : false",
                        maxlength="60",
                        :class="{ invalid: $v.personNameMutable.$dirty && !$v.personNameMutable.required }"
                    )
                    button.accept(
                        @click.prevent="validateName",
                        :disabled="personName === personNameMutable || !$v.personNameMutable.required || !$v.personNameMutable.maxLength"
                    )
                        svg-icon(icon-name="check")

                    .input-error(
                        v-if="$v.personNameMutable.$dirty && !$v.personNameMutable.required"
                    ) {{ locale("person-name") }}
                    .input-error(
                        v-if="$v.personNameMutable.$dirty && !$v.personNameMutable.maxLength"
                    ) {{ locale("name-error") }}
                    .input-error(v-if="nameError") {{ nameError }}
                ._item
                    label {{ locale("address") }}
                    input.less-width(
                        v-model="$v.temporaryPublicAddress.$model",
                        maxlength="30",
                        :class="{ invalid: $v.temporaryPublicAddress.$error }"
                    )
                    button.accept(
                        @click.prevent="validateAddress",
                        :disabled="temporaryPublicAddress === personUrl || !$v.temporaryPublicAddress.cyrillicExclusion || !$v.temporaryPublicAddress.$params.maxLength.max || !$v.temporaryPublicAddress.$model.length"
                    )
                        svg-icon(icon-name="check")
                    .input-error(
                        v-if="!$v.temporaryPublicAddress.cyrillicExclusion && $v.temporaryPublicAddress.$dirty"
                    ) {{ locale("address-error") }}
                    .input-error(v-if="addressError.length") {{ addressError }}
                    .input-error(
                        v-if="temporaryPublicAddress > $v.temporaryPublicAddress.$params.maxLength.max && $v.temporaryPublicAddress.$dirty"
                    ) {{ locale("address-length-error", {min: $v.temporaryPublicAddress.$params.maxLength.min}) }}
                ._item
                    label {{ locale("birthday") }}
                    date-picker(
                        :class="{ 'personal-data__date-picker-error': $v.date_of_birth.$dirty && !$v.date_of_birth.mustBeDate }",
                        v-model="date_of_birth",
                        type="date",
                        prefix-class="merlinface",
                        valueType="YYYY-MM-DD",
                        format="DD.MM.YYYY",
                        :default-value="date_of_birth || new Date()",
                        value-type="date",
                        clearable=false,
                        :lang="lang",
                        @change="updatePersonData"
                    )
                    .input-error(v-if="$v.date_of_birth.$dirty && !$v.date_of_birth.mustBeDate") {{ locale("birthday-error") }}
                ._item
                    label {{ locale("marital") }}
                    multiselect._input(v-model="marriage_status" :options="marriageStatusList" :searchable="false" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('select')" @input="updatePersonData")
                ._item
                    label {{ locale("children") }}
                    input(
                        v-model="$v.number_of_children.$model",
                        maxlength="2",
                        :class="{ invalid: ($v.number_of_children.$dirty && !$v.number_of_children.numeric) }"
                    )
                    .input-error(
                        v-if="($v.number_of_children.$dirty && !$v.number_of_children.numeric)"
                    ) {{ locale("children-error") }}

                ._item
                    label {{ locale("height") }}
                    input(
                        v-model="$v.height.$model",
                        maxlength="3",
                        :class="{ invalid: ($v.height.$dirty && !$v.height.numeric) || ($v.height.$dirty && !$v.height.required) }"
                    )
                    span.unit {{ locale("heightUnit") }}
                    .input-error(
                        v-if="($v.height.$dirty && !$v.height.numeric) || ($v.height.$dirty && !$v.height.required)"
                    ) {{ locale("height-error") }}
                    .input-error(
                        v-if="heightError"
                    ) {{heightError}}
                ._item
                    label {{ locale("weight") }}
                    input(
                        v-model="$v.weight.$model",
                        maxlength="3",
                        :class="{ invalid: ($v.weight.$dirty && !$v.weight.numeric) || ($v.weight.$dirty && !$v.weight.required) }"
                    )
                    span.unit {{ locale("weightUnit") }}
                    .input-error(
                        v-if="($v.weight.$dirty && !$v.weight.numeric) || ($v.weight.$dirty && !$v.weight.required)"
                    ) {{ locale("weight-error") }}
                    .input-error(
                        v-if="weightError"
                    ) {{weightError}}
                ._item
                    label {{ locale("gender") }}
                    multiselect._input(v-model="gender" :options="genderList" :searchable="false" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('select')" @input="updatePersonData")

                ._item
                    label {{ locale("eye-color") }}
                    multiselect._input(v-model="eye" :options="eyeColors" :searchable="false" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('select')" @input="updatePersonData")

                ._item
                    label {{ locale("lang") }}
                    lang-switcher

                ._item
                    transition-expand
                        span._time-stamp(v-if="timeStamp") {{ locale("saved") }} {{ timeStamp }}
                connect-social.connect-social
                ._logout(@click="logout") {{ locale("exit") }}
                ._delete(@click="confirmDeleteAccount") {{ locale("delete-account") }}
</template>
<script>
import DatePicker from "vue2-datepicker";
import ConnectSocial from "@/components/person/connect-social/connect-social";
import { maxLength, numeric, required } from "vuelidate/lib/validators";
import gql from "graphql-tag";
import PersonChangePublicUrlMutation from "@/graphql/mutations/personChangePublicUrl.mutation.graphql";
import { mapMutations } from "vuex";
import UiButton from "../../ui/ui-button/ui-button";
import personEditPersonalData from "@/graphql/mutations/personEditPersonalData.mutation.graphql";
import ChangePhotos from "@/components/person/change-photos/change-photos";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import personProfileQuery from "@/graphql/queries/personProfile.query.graphql";
import AboutSettingsForm from "@/components/person/settings/about-form/about-form";
import CareerSettingsForm from "@/components/person/settings/career-form/career-form";
import EducationSettingsForm from "@/components/person/settings/education-form/education-form";
import Multiselect from "vue-multiselect/src";
import InfoSettingsForm from "@/components/person/settings/info-form/info-form";
import userDeleteMutation from "@/graphql/mutations/userDelete.mutation.graphql";
import LangSwitcher from "@/components/app/lang-switcher/lang-switcher.vue";

// Функция проверки даты
const mustBeDate = function (value) {
    const regExp = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}"); // YYYY-MM-DD
    return regExp.test(value);
};

// Функция исключения кириллицы
const cyrillicExclusion = function (value) {
    if (value) {
        const regExp = new RegExp(/^[a-z\-0-9_]{3,30}$/);
        return regExp.test(value);
    }
    return true;
};

export default {
    name: "Settings",
    components: {
        LangSwitcher,
        InfoSettingsForm,
        EducationSettingsForm,
        CareerSettingsForm,
        AboutSettingsForm,
        UiButton,
        ConnectSocial,
        DatePicker,
        ChangePhotos,
        TransitionExpand,
        Multiselect,
    },
    props: {
        personalData: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        personName: {
            type: String,
            default: "",
            required: false,
        },
        personUrl: {
            type: String,
            default: "",
            required: false,
        },
        personEye: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        userUuid: {
            type: String,
            default: "",
            required: false,
        },
    },
    data() {
        return {
            // Current tab
            currentTab: "information",
            // For info settings
            timeout: null,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: false,
            },
            date_of_birth: 0,
            temporaryPublicAddress: "",
            addressError: "",
            nameError: "",
            personNameMutable: "",
            height: "",
            heightError: null,
            weightError: null,
            weight: "",
            eye: "",
            eyeColors: [],
            gender: "",
            timeStamp: "",
            timeStampTimeout: null,
            isTimeDisplayed: false,
            personProfile: {},
            marriage_status: "",
            number_of_children: null,
            photoLoading: false,
            anfasChange: false,
            profileChange: false,
        };
    },
    computed: {
        settingsType: (state) => state.$route.params.type,
        marriageStatusList: (state) => state.locale("maritalList"),
        genderList: (state) => state.locale("genderList"),
        anfas: function () {
            const photo = this.$store.getters["user/photos"]["anfas"];
            return photo?.includes("dummy") ? null : photo;
        },
        profile: function () {
            const photo = this.$store.getters["user/photos"]["profile"];
            return photo?.includes("dummy") ? null : photo;
        },
        photosReady: (state) =>
            !!(state.anfasChange || state.anfas) &&
            !!(state.profileChange || state.profile),
    },
    watch: {
        anfas() {
            this.anfasChange = true;
        },
        profile() {
            this.profileChange = true;
        },
        photosReady(status) {
            if (status) {
                this.generateDescription();
            }
        },
        currentTab(tab) {
            this.$router.replace(`/settings/${tab}`);
        },
        //ДАННЫЕ ИЗ ПРОПСОВ
        personalData(personal) {
            let defaultColor = {
                id: "4",
                name: "Зелёный",
            };
            this.gender = this.genderList.find((g) => g.id === personal.gender);
            this.eye = this.myPerson.eye_color || defaultColor;
            this.height = personal.height || "";
            this.weight = personal.weight || "";

            this.date_of_birth = personal.date_of_birth
                ? personal.date_of_birth.split("T")[0]
                : 0;

            this.marriage_status = personal.marriage_status
                ? personal.marriage_status
                : "";

            this.number_of_children = personal.number_of_children;
        },
        personName(personal) {
            this.personNameMutable = personal || "";
        },
        personUrl(personal) {
            this.temporaryPublicAddress = personal || "";
        },
        personEye(personal) {
            this.eyeColorName = personal.name || "";
            this.eyeColorId = personal.id;
        },

        // ЛОКАЛЬНЫЕ ДАННЫЕ ПОЛЕЙ РОСТ И ВЕС
        "$v.height.$model"() {
            this.validateHeight();
        },
        "$v.weight.$model"() {
            this.validateWeight();
        },
        "$v.number_of_children.$model"() {
            this.validateChildren();
        },
    },
    async mounted() {
        await this.getPersonProfile();
        if (this.settingsType) {
            this.currentTab = this.settingsType;
        }
        this.temporaryPublicAddress = this.personUrl;
        this.personNameMutable = this.personName;
        this.eyeColors = await this.$store
            .dispatch("getEyeColorList", {
                apolloClient: this.$apollo,
            })
            .catch(() => {
                this.$msgBox(
                    this.localeError("attention"),
                    this.$options.filters.localizeError(
                        "personDataNotReceived",
                    ),
                );
            });
    },
    methods: {
        ...mapMutations({
            setNewPersonalData: "person/setNewPersonalData",
        }),
        logout() {
            this.$store.dispatch("auth/signout");
            this.$router.push({ name: "Login" });
            this.$router.go();
        },
        changeModel(selectValues) {
            this[selectValues.fieldId] = selectValues.fieldIdValue;
            this[selectValues.fieldName] = selectValues.fieldNameValue;

            this.updatePersonData();
        },
        displayTime() {
            let date = new Date();
            this.timeStamp = `${date.getHours()}:${
                date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes()
            }`;
            this.timeStampTimeout = setTimeout(() => {
                this.timeStamp = null;
                clearTimeout(this.timeStampTimeout);
                this.timeStampTimeout = null;
            }, 2000);
        },

        // Методы ниже проверяют правильность входных данных, ошибки не выводят так как используется real-time валидация
        validateName() {
            if (!this.$v.personNameMutable.$invalid) {
                this.updatePersonData();
            }
        },
        validateHeight() {
            if (
                !this.$v.height.$invalid &&
                this.$v.height.$model !== this.personalData.height &&
                this.$v.height.$model.length > 1
            ) {
                if (this.height > 200) {
                    this.heightError = this.locale("maxHeightError");
                    return;
                }
                this.heightError = null;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.updatePersonData(), 1000);
            }
        },
        validateWeight() {
            if (
                !this.$v.weight.$invalid &&
                this.$v.weight.$model !== this.personalData.weight &&
                this.$v.weight.$model.length > 1
            ) {
                if (this.weight > 200) {
                    this.weightError = this.locale("maxWeightError");
                    return;
                }
                this.weightError = null;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.updatePersonData(), 1000);
            }
        },
        validateChildren() {
            if (
                !this.$v.number_of_children.$invalid &&
                this.$v.number_of_children.$model !==
                    this.personalData.number_of_children
            ) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.updatePersonData(), 1000);
            }
        },
        validateAddress() {
            if (!this.$v.temporaryPublicAddress.$invalid) {
                this.updatePublicUrl();
            }
        },

        // Мутация основых данных person_data
        async updatePersonData() {
            this.nameError = "";

            console.log(this.marriage_status);
            let variables = {
                name: this.personNameMutable,
                gender: this.gender.id || null,
                height: Number(this.height) || 1,
                weight: Number(this.weight) || 1,
                date_of_birth: this.date_of_birth,
                eye_color: Number(this.eye.id),
                person_id: this.userUuid,
                marriage_status: this.marriage_status,
                number_of_children: Number(this.number_of_children)
                    ? Number(this.number_of_children)
                    : null,
            };
            this.$apollo
                .mutate({
                    mutation: gql`
                        ${personEditPersonalData}
                    `,
                    variables,
                })
                .then((response) => {
                    this.setNewPersonalData(
                        //мутация из модуля person в $store
                        response.data.personEditPersonalData,
                    );
                    this.displayTime();
                    this.loadMyUser();
                    this.$emit("personDataUpdated");
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1013:
                                this.nameError = this.locale("nameTimeError");
                                this.personNameMutable = this.personName;
                                break;

                            default:
                                break;
                        }
                    }
                });
        },

        // Мутация короткого пути
        async updatePublicUrl() {
            this.addressError = "";
            if (this.temporaryPublicAddress === this.personUrl) {
                return;
            }
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${PersonChangePublicUrlMutation}
                    `,
                    variables: {
                        url: this.temporaryPublicAddress,
                        personId: this.$store.getters["user/uuid"],
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.$v.$reset();
                    this.$store.dispatch(
                        "person/setPersonUrl",
                        this.temporaryPublicAddress,
                    );
                    if (this.temporaryPublicAddress !== "") {
                        this.$emit("personDataUpdated");
                        this.displayTime();
                    }
                })
                .catch((e) => {
                    this.addressError = "Неизвестная ошибка.";
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1007:
                                this.addressError = "Адресс уже занят";
                                break;
                            case 1006:
                                this.addressError =
                                    "Неправильный адрес, попробуйте другой";
                                break;
                        }
                    }
                });
        },

        async getPersonProfile() {
            await this.$apollo
                .query({
                    query: gql(personProfileQuery),
                    variables: {
                        id: this.myPerson.uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.personProfile = r.data.personProfile;
                });
        },
        confirmDeleteAccount() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Удаление аккаунта",
                text: "После подтверждения Ваш аккаунт будет безвозвратно удалён, и Вы не сможете пользоваться другими сервисами компании Merlin AI с этой учетной записи. Вы подтверждаете удаление?",
                buttons: true,
                confirmText: "Удалить",
                callback: this.deleteAccount,
            });
        },
        deleteAccount() {
            this.$apollo
                .mutate({
                    mutation: gql(userDeleteMutation),
                    variables: {
                        uuid: this.myUser.uuid,
                    },
                })
                .then(() => {
                    this.$store.dispatch("auth/signout");
                    this.$router.push({ name: "Login" });
                    this.$router.go();
                });
        },
    },
    validations: {
        personNameMutable: {
            required,
            maxLength: maxLength(60),
        },
        weight: {
            numeric,
            required,
        },
        height: {
            numeric,
            required,
        },
        date_of_birth: {
            mustBeDate,
            required,
        },
        temporaryPublicAddress: {
            maxLength: maxLength(30),
            cyrillicExclusion,
        },
        number_of_children: {
            numeric,
        },
    },
};
</script>

<style src=""></style>
<style lang="scss">
@import "./settings.scss";
</style>
