<template lang="pug">
  .settings__inputs-wrap
    .settings__item
      label {{ locale("web") }}
      input(v-model="web" @blur="saveData")
      .personal-data__input-error(v-if="$v.web.$invalid") {{ locale("address-incorrect") }}
    .settings__item
      label {{ locale("selfEsteem") }}
      input(v-model="selfEsteem" @blur="saveData")
      .personal-data__input-error(v-if="$v.selfEsteem.$invalid") {{ locale("self-esteem-error", {count: selfEsteem.length}) }}

    .settings__item
      label {{ locale("social-status") }}
      ui-checkbox(v-for="(social, index) in socialList" :id="social" :key="index" :label="social" type="radio" name="social" v-bind:model.sync="occupation" :checked="social === occupation")._checkbox

    .settings__item
      label {{ locale("country") }}
      multiselect._input(v-model="country" :searchable="false" :options="countries" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('selectCountry')")

    .settings__item
      label {{ locale("city") }}
      multiselect._input(v-model="city" :disabled="!country" :options="cities" :searchable="true" :loading="isCityLoading" @search-change="searchCity" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('searchCities')")

    .settings__item
      transition-expand
        span.settings__time-stamp(v-if="isSaved") {{ locale("saved") }}
</template>

<script>
import gql from "graphql-tag";
import personEditInfoMutation from "@/graphql/mutations/personEditInfo.mutation.graphql";
import UiCheckbox from "@/components/ui/ui-checkbox/ui-checkbox";
import multiselect from "vue-multiselect/src";
import getCountriesListQuery from "@/graphql/queries/getCountriesList.query.graphql";
import getCitiesListQuery from "@/graphql/queries/getCitiesList.query.graphql";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import { url, maxLength } from "vuelidate/lib/validators";

export default {
    name: "InfoSettingsForm",
    components: { TransitionExpand, UiCheckbox, multiselect },
    props: {
        person: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            web: "",
            selfEsteem: "",
            country: "",
            city: "",
            occupation: "",
            countries: [],
            cities: [],
            dataReady: false,
            isCityLoading: false,
            isSaved: false,
        };
    },
    computed: {
        socialList: (state) => state.locale("socialList"),
    },
    validations: {
        web: {
            url,
        },
        selfEsteem: {
            maxLength: maxLength(50),
        },
    },
    watch: {
        occupation() {
            if (!this.dataReady) return;
            this.saveData();
        },
        country: {
            handler() {
                this.city = "";
                this.cities = [];
                if (!this.dataReady) return;
                this.searchCity("");
                this.saveData();
            },
            deep: true,
        },
        city: {
            handler() {
                if (!this.dataReady) return;
                this.saveData();
            },
            deep: true,
        },
    },
    async created() {
        await this.getCountries();

        this.web = this.person.info.web;
        this.selfEsteem = this.person.info.self_esteem;
        this.occupation = this.person.info.occupation;
        this.country = await this.countries.find(
            (c) => c.name === this.person.info.country,
        );
        if (!this.person.info.country) {
            setTimeout(() => {
                this.dataReady = true;
            }, 400);
            return;
        }

        if (this.person.info.city) {
            await this.searchCity(this.person.info.city.split(",")[0], true);
        } else {
            await this.searchCity("");
        }
    },
    methods: {
        async saveData() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            await this.$apollo
                .mutate({
                    mutation: gql(personEditInfoMutation),
                    variables: {
                        personId: this.myPerson.uuid,
                        input: {
                            web: this.web,
                            self_esteem: this.selfEsteem,
                            occupation: this.occupation,
                            country: this.country?.name || null,
                            city: this.city?.name || null,
                        },
                    },
                })
                .then(() => {
                    this.isSaved = true;
                    this.$emit("update");
                    setTimeout(() => {
                        this.isSaved = false;
                    }, 4000);
                });
        },
        async getCountries() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getCountriesListQuery}
                    `,
                })
                .then((r) => {
                    this.countries.push(...r.data.getCountriesList);
                });
        },
        searchCity(query, setDefault = false) {
            if (!this.country) return;
            this.isCityLoading = true;
            clearTimeout(this.cityTimeout);
            this.cityTimeout = setTimeout(async () => {
                await this.$apollo
                    .query({
                        query: gql`
                            ${getCitiesListQuery}
                        `,
                        variables: {
                            countryId: Number(this.country.id),
                            term: query,
                        },
                    })
                    .then((r) => {
                        this.cities = r.data.getCitiesList;
                        if (setDefault) {
                            this.city = this.cities.find(
                                (c) => c.name === this.person.info.city,
                            );
                        }
                        setTimeout(() => {
                            console.log("data ready");
                            this.dataReady = true;
                        }, 400);
                        this.isCityLoading = false;
                    });
            }, 500);
        },
    },
};
</script>
