<template lang="pug">
  settings(
    :personalData="personal_data",
    :personName="personName",
    :personUrl="personUrl",
    :personEye="personEye",
    :userUuid="userUuid",
    @personDataUpdated="loadMyUser"
  )
</template>

<script>
import Settings from "../components/person/settings/settings";
import { mapGetters } from "vuex";

export default {
    components: { Settings },
    computed: {
        ...mapGetters({
            personal_data: "user/personalData",
            personEye: "user/eyeColor",
            personName: "user/name",
            personUrl: "user/public_url",
            userUuid: "user/uuid"
        })
    },
    async mounted() {
        await this.loadMyUser();
        this.$store.dispatch("setPage", "settings");
    }
};
</script>
